// local
// export const API_URI = `http://localhost:1337`;
// export const IMAGE_API_URI = `http://localhost:1337`;
// staging
// export const API_URI = `https://aag.operaticsites.com/api`;
// export const IMAGE_API_URI = `/api`; //staging
// prod
export const API_URI = `https://appleautoglass.com/api`;
export const IMAGE_API_URI = ``; //prod

export const GOOGLE_API_KEY = `AIzaSyAAH4n71GFqJK6QsUEshXrzXwBT6264jK8`;
export const DEFAULT_ZOOM = 1;
export const CITY_ZOOM = 14;
export const DEFAULT_CENTER = [parseFloat(58.131047), parseFloat(-93.173614)];
